import Vue from 'vue';
import wysiwyg from 'vue-wysiwyg';
import 'vue-wysiwyg/dist/vueWysiwyg.css';
// import vue2Dropzone from 'vue2-dropzone'
// import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import store from '../store';
// import api from './axios';
import config from '../config';

Vue.use(wysiwyg, {
  image: {
    // uploadURL: api({
    //   method: 'post',
    //   url: `/api/channels/${store.state.workspace.activeWorkspace._id}/attachment`,
    // }),
    // uploadURL: `${config.api.baseUrl}/api/channels/${store.state.workspace.activeWorkspace._id}/attachment`,
    uploadURL: `${config.api.baseUrl}/api/channels/${store.state.workspace.activeWorkspace._id}/attachment`,
    dropzoneOptions: {
      // url: `${config.api.baseUrl}/api/channels/${store.state.workspace.activeWorkspace._id}/attachment`,
      // thumbnailWidth: 150,
      // maxFilesize: 0.5,
      // withCredentials: true,
      headers: { Authorization: `bearer ${store.state.auth.token}` },
    },
  },
  maxHeight: '1000px',
});
