<template>
  <div id="app">
    <DashboardLayout v-if="$route.meta.layout == 'dashboard'">
      <router-view :key="$route.fullPath" />
    </DashboardLayout>
    <TopOnlyLayout v-else-if="$route.meta.layout == 'topOnly'">
      <router-view />
    </TopOnlyLayout>
    <DefaultLayout v-else>
      <router-view />
    </DefaultLayout>
  </div>
</template>

<script>
export default {
  name: 'App',
  metaInfo() {
    return {
      titleTemplate: (s) => (s ? `${s} | Wappin` : 'Wappin'),
    };
  },
  mounted() {
    if (this.$store.getters['auth/isLogin']) {
      // this.$store.dispatch('workspace/loadWorkspaces');
    }
  },
  components: {
    DashboardLayout: () => import('./ui/layouts/DashboardLayout.vue'),
    DefaultLayout: () => import('./ui/layouts/DefaultLayout.vue'),
    TopOnlyLayout: () => import('./ui/layouts/TopOnlyLayout.vue'),
  },
};
</script>
